import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg"


export function Contact(){

  return(
    <Container className="contact" id="contato">
      <header>
        <h2>Contact Us</h2>
        <p>I am available on almost every social media. You can message me, I will reply within 24 hours.</p>
      </header>
      <div className="contacts">
        <div>
          <b>Email Address:</b>
          <a href="mailto:abodmicheal@gmail.com">abodmicheal@gmail.com</a>
        </div>
        <div>
        <b>Phone Number:</b>
          <a href="tel:+2347042137545">+2347042137545</a>
        </div>  
        <div>
        </div> 
      </div>
    </Container>
  )
}