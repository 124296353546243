import { Container } from "./styles";
import githubIcon from "../../assets/github-icon.svg"
import Screen1 from "../../assets/Screen1.png"
import Screen2 from "../../assets/Screen2.png"
import Screen3 from "../../assets/Screen3.png"
import Screen4 from "../../assets/Screen4.png"
import Screen5 from "../../assets/Screen5.png"
import externalLinkIcon from "../../assets/external-link-icon.svg"
import ScrollAnimation from "react-animate-on-scroll";


export function Portfolio(){
  return(
    <Container id="portfolio">
      <h2>My Projects</h2>

      <div className="projects">

      <ScrollAnimation animateIn="flipInX">
        <div className="project">
          <header>
            <div className="project-links">
            <a href="https://github.com/abodmicheal/talktik" target="_blank" rel="noreferrer">
              <img src={githubIcon} alt="GitHub" />
            </a>
          </div>
          </header>
          <div className="body">
            <h3>TalkTik React js library</h3>
            <a href="https://github.com/abodmicheal/talktik" target="_blank" rel="noreferrer">
              <img src='https://raw.githubusercontent.com/abodmicheal/talktik/main/images/example-3.png' style={{borderRadius: '5px'}} alt="GitHub" width="100%" />
            </a>
          </div>
          <footer>
            <ul className="tech-list">
              <li>React Library</li>
            </ul>
          </footer>
        </div>
      </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
                <div className="project-links">
              <a href="https://github.com/abodmicheal/react-js-dialog-box" target="_blank" rel="noreferrer">
                <img src={githubIcon} alt="GitHub" />
              </a> 
              
              <a href="https://www.npmjs.com/package/react-js-dialog-box" target="_blank" rel="noreferrer">
                <img src={externalLinkIcon} alt="visit site" />
              </a>
            </div>
            </header>
            <div className="body">
              <h3>Dialog box React js library</h3>
              <a href="https://www.npmjs.com/package/react-js-dialog-box" target="_blank" rel="noreferrer">
              <img src={Screen3} style={{borderRadius: '5px'}} alt="GitHub" width="100%" />
            </a>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Library</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
                <div className="project-links">
                <a href="https://github.com/abodmicheal/react-js-stickynav" target="_blank" rel="noreferrer">
                <img src={githubIcon} alt="visit site" />
              </a>
              <a href="https://www.npmjs.com/package/react-js-stickynav" target="_blank" rel="noreferrer">
                <img src={externalLinkIcon} alt="visit site" />
              </a>
            </div>
            </header>
            <div className="body">
              <h3>Sticky nav React library </h3>
              <a href="https://www.npmjs.com/package/react-js-stickynav" target="_blank" rel="noreferrer">
              <img src={Screen4} style={{borderRadius: '5px'}} alt="GitHub" width="100%" />
            </a>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React library</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
                <div className="project-links">
                <a href="https://github.com/abodmicheal/React-projects/" target="_blank" rel="noreferrer">
                <img src={githubIcon} alt="visit site" />
              </a>
              <a href="https://abod-bnb.web.app/" target="_blank" rel="noreferrer">
                <img src={externalLinkIcon} alt="visit site" />
              </a>
            </div>
            </header>
            <div className="body">
              <h3>Home/Vacation Rentals</h3>
              <a href="https://abod-bnb.web.app/" target="_blank" rel="noreferrer">
              <img src={Screen1} style={{borderRadius: '5px'}} alt="GitHub" width="100%" />
            </a>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Reactjs</li>
                <li>Material ui</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
                <div className="project-links">
              <a href="https://github.com/abodmicheal/React-projects/" target="_blank" rel="noreferrer">
                <img src={githubIcon} alt="visit site" />
              </a>
              <a href="https://abod-zone.web.app/" target="_blank" rel="noreferrer">
                <img src={externalLinkIcon} alt="visit site" />
              </a>
            </div>
            </header>
            <div className="body">
              <h3>Ecommerce Web App</h3>
              <a href="https://abod-zone.web.app/" target="_blank" rel="noreferrer">
              <img src={Screen2} style={{borderRadius: '5px'}} alt="GitHub" width="100%" />
            </a>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Redux</li>
                <li>Material ui</li>
                <li>Firebase + User auth</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
                <div className="project-links">
              <a href="https://github.com/abodmicheal/React-projects/" target="_blank" rel="noreferrer">
                <img src={githubIcon} alt="visit site" />
              </a>
              <a href="https://abod-bnb.web.app/" target="_blank" rel="noreferrer">
                <img src={externalLinkIcon} alt="visit site" />
              </a>
            </div>
            </header>
            <div className="body">
              <h3><li>Netflix UI Web App</li></h3>
              <a href="https://abod-bnb.web.app/" target="_blank" rel="noreferrer">
              <img src={Screen5} style={{borderRadius: '5px'}} alt="GitHub" width="100%" />
            </a>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Material UI</li>
                <li>Sticky Nav Library</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>



      </div>
    </Container>
  );
}