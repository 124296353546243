import { Container } from "./styles";

import reduxIcon from "../../assets/redux.svg";
import tailwindIcon from "../../assets/tailwindcss.svg";
import laravelIcon from "../../assets/laravel-icon.svg";
import mysqlIcon from "../../assets/mysql-icon.svg";
import cssIcon from "../../assets/css-icon.svg";
import htmlIcon from "../../assets/html-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import abod_micheal from "../../assets/abod_micheal.png";
// import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
// import typescriptIcon from "../../assets/typescript-icon.svg";
// import vueIcon from "../../assets/vue-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";


export function About(){
  return(
    <Container id="sobre">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.6 * 1000}>
       <p>I'm a React/front end developer who started coding and ethical hacking at a young age as a way to deal with my insomnia.  </p>
       </ScrollAnimation>
       <ScrollAnimation animateIn="fadeInLeft" delay={0.6 * 1000}>
       <p>I've always been curious about how the internet works and how web pages load and are organized. </p>
       </ScrollAnimation>
       <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>I stopped coding and went into photography and cinematography, but I'm back to it fully now, my enthusiasm for photography and cinema remains alive and well.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>- 🔭 I’m currently working on alot of React and Redux projects and libraries</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>- 🌱 I’m currently learning animations, web3 and alot i can't write out</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>- 💬 You can ask me about how to solve any Front End issues </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000} style={{marginTop: "2rem", marginBottom: "2rem"}}>

          <p>- ⚡ Fun fact: I watch animes alot like a whole lot 🚶‍♂️🚶‍♂️</p>
        </ScrollAnimation>
      

        <ScrollAnimation animateIn="fadeInLeft" delay={0.7 * 1000}>
          <h3>Languages and Tools:</h3>
        </ScrollAnimation>
        <div className="hard-skills">

          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
              <img src={reactIcon} alt="React" />
            </ScrollAnimation>
          </div>

          <div className="hability">
          <ScrollAnimation animateIn="fadeInUp" delay={0.3 * 1000}> 
            <img src={jsIcon} alt="JavaScript" />
          </ScrollAnimation>
          </div>

          <div className="hability">
          <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}> 
            <img src={htmlIcon} alt="Html" />
          </ScrollAnimation>
          </div>

          <div className="hability">
          <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}> 
            <img src={cssIcon} alt="Css" />
          </ScrollAnimation>
          </div>
          <div className="hability">
          <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}> 
            <img src={reduxIcon} alt="Redux" />
          </ScrollAnimation>
          </div>

          <div className="hability">
          <ScrollAnimation animateIn="fadeInUp" delay={0.7 * 1000}>
            <img src={tailwindIcon} alt="Tailwind" />
          </ScrollAnimation>
          </div>
          <div className="hability">
          <ScrollAnimation animateIn="fadeInUp" delay={0.7 * 1000}>
            <img src={laravelIcon} alt="Laravel" />
          </ScrollAnimation>
          </div>
          <div className="hability">
          <ScrollAnimation animateIn="fadeInUp" delay={0.7 * 1000}>
            <img src={mysqlIcon} alt="Mysql" />
          </ScrollAnimation>
          </div>

        </div>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.6 * 1000}>
          <img src={abod_micheal} alt="Abod micheal's photo" />
        </ScrollAnimation>
      </div>
    </Container>
  )
}
