import { Container } from './styles'

import reactIcon from '../../assets/react-icon.svg'
import linkedinIcon from '../../assets/linkedin.png'
import githubIcon from '../../assets/github.png'
import instagramIcon from '../../assets/instagram.png'
import twitterIcon from '../../assets/twitter-icon.svg'
import whatsappIcon from '../../assets/whatsapp-icon.svg'
import devIcon from '../../assets/dev-icon.svg'
import discordIcon from '../../assets/discord.png'

export function Footer() {
  return (
    <Container className="footer">
      <div>
        <p>
        abodmicheal@gmail.com
        </p>
      </div>

      <div className="social-media">
        <a
          href="https://www.linkedin.com/in/abodmicheal/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedinIcon} alt="Linkedin" />
        </a>

        <a
          href="https://github.com/abodmicheal/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>

        <a
          href="https://www.instagram.com/abod_micheal/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </a>
        <a
          href="https://twitter.com/abod_micheal"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitterIcon} alt="Twitter" />
        </a>
        <a
          href="https://wa.link/q61zyb"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsappIcon} alt="Twitter" />
        </a>
        <a
          href="https://discord.com/users/abodmicheal#9017"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discordIcon} alt="Discord" />
        </a>
        <a
          href="https://dev.to/abodmicheal"
          target="_blank"
          rel="noreferrer"
        >
          <img src={devIcon} alt="Dev" />
        </a>
      </div>
    </Container>
  )
}
